body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(242, 246, 252);
  font-size: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  white-space: pre-line;
  color: #666666;
}

.hidden {
  display: none;
}

#denovo-logo {
  height: 70px;
  cursor: pointer;
  vertical-align: middle;
}

.image-comparator-main-div {
  margin-top: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 5px, rgba(0, 0, 0, 0.1) 0px 5px 5px;
  height: auto;
  width: 90%;
  max-width: 1000px;
  padding-left: 2%;
  padding-right: 2%;
}
/**************************************************************/
.horizontal-panel {
  height: auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.recaptcha{
  margin-top: 10px;
  margin-bottom: 10px;
}

/*DRAG AND UPLOAD IMAGE COMPONENT*/
/**************************************************************/
#form-file-upload {
  height: 300px;
  width: 630px;
  max-width: 100%;
  text-align: center;
  position: relative;
  font-size: 1rem;
  padding-top: 3%;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
/**************************************************************/


/* RADIO GROUP */
/**************************************************************/
/* HIDE RADIO */
[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  width: 100px;
  height: auto;
  cursor: pointer;
  max-width: 100%;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid black;
}

* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33%;
  padding: 10px;
}

.column > .item {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 5px, rgba(0, 0, 0, 0.1) 0px 5px 5px;
  color: #666666;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
}


.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
/*@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}*/
/**************************************************************/

/* IMAGE CONTAINER */
.image-processor{
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
}

.image-processor-container{
  position: relative;
  display: grid;
  grid-template-areas:
    "top-left top-right"
    "bottom-left bottom-right";
  grid-template-columns: 70% 25%;
  grid-template-rows: auto auto;
  column-gap: 4%;
  row-gap: 10px;
  box-sizing: border-box;
}

.image-processor-container-top-left {
  grid-area: top-left;
  max-height: 100%;
}

/* CSS code for the top-right panel */
.image-processor-container-top-right {
  grid-area: top-right;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  overflow: auto;
  max-height: 450px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 5px, rgba(0, 0, 0, 0.1) 0px 5px 5px;
}

/* CSS code for the bottom-left panel */
.image-processor-container-bottom-left {
  grid-area: bottom-left;
  box-sizing: border-box;
}

/* CSS code for the bottom-right panel */
.image-processor-container-bottom-right {
  grid-area: bottom-right;
  box-sizing: border-box;
}


@media (max-width: 600px) {

  .image-processor-container-top-left{
    grid-row: 1;
    width: 144%;
  }

  .image-processor-container-top-right{
    grid-area: bottom-left;
    grid-row: 2;
    width: 144%;
  }

  .image-processor-container-bottom-left {
    grid-row: 4;
  }

  .image-processor-container-bottom-right {
    grid-area: bottom-left;
    grid-row: 3;
    width: 150%;
  }

  .recaptcha{
    transform: scale(0.6)
  }
}


.objectTags {
  left: 0px;
  top: 0px;
  z-index: 1000;
  padding: 5px;
  font-weight: bold;;
  width: 100%;
}

.image-comparator-image-panel {
  height: 100%;
}

.image {
  position: relative;
}

canvas {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 5px, rgba(0, 0, 0, 0.1) 0px 5px 5px;
}
/**************************************************************/

.loading-spinner{
  border: 16px solid #f3f3f3;
  border-top: 16px solid black;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
/**************************************************************/

/**************************************************************/
.reset-buttons{
  display: flex;
  flex-wrap: wrap;
  gap:5%;
}
.submitButton {
  background-color: #F5F5F5;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: gray;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  padding-top:  1rem;
  padding-bottom:  1rem;
  text-align: center;
  text-decoration: none #8C8C8C solid;
  text-decoration-thickness: auto;
  transition: all cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  touch-action: manipulation;
  width: 100%;
  width: 45%;
  overflow: hidden;
}

.submitButton:hover {
  background-color: #8C8C8C;
  color: black;
}


/**************************************************************/
/***COLLAPSIBLE PANEL****/
.toggle{
  display: none;
}

.lbl-toggle{
  display: block;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #2c85EA;
  cursor: pointer; 
  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover{
  color: #0750A4;
}

.lbl-toggle::before{
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform .2s ease-out;
}

.toggle:checked+.lbl-toggle::before{
  transform: rotate(90deg) translateX(-3px);
}

.collapsible-content{
  max-height: 0px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content{
  max-height: 50000px;
}

.toggle:checked + .lbl-toggle{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
  border-bottom: 1px solid gray;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: .5rem 1rem;
}

/**************************************************************/
/***IDENTIFIED OBJECT ITEMS****/
.object-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.object-item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  color: #666666;
}

.object-item-name {
  font-weight: bold;
}

.object-item-progress {
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
}

.object-item-progress-bar {
  height: 100%;
  background-color: #2CA96C;
  border-radius: 5px;
}
